export const UPSET_PHRASES : string[] = [
    "That's not bear-y nice...",
    "Please stop that...",
    "You're being un-bear-able"
]


export const WORKING_INITAL_MESSAGE = "Career Bear appears to be sleeping, maybe pressing 'start session' will wake him up..."


